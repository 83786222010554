// This file was automatically generated from admin.concierge.list.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Admin == 'undefined') { Nightloop.Templates.Admin = {}; }


Nightloop.Templates.Admin.ConciergeList = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Admin.Layout(soy.$$augmentData(opt_data, {is_sevenrooms_admin: opt_data.content.is_sevenrooms_admin, DynamicTemplate: 'Nightloop.Templates.Admin.ConciergeListBit'}), output);
  Nightloop.Templates.Admin.ConciergeTableScript(null, output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Admin.ConciergeListBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('\n  <style type="text/css">\n    tr.group,\n    tr.group:hover {\n        background-color: #ddd !important;\n    }\n  </style>\n  <div id="conciergelist-page"><div class="content-header">Concierges</div><div style="font-size:10px;">Tip: shift+click to sort by multiple columns</div><div class="right vpadding"><a id="concierge-add-new-link" class="ajaxify" href="/admin/concierge/create">+ create new</a></div><table id="concierge-list-table" style="width:100%; background:#fff;" class="display nowrap"><thead><td>Name</td><td>URL key</td><td>ID</td><td>Group</td><td>Muni</td><td class="right" width="110">Actions</td></thead><tbody>');
  var muni_conciergesList375 = opt_data.content.concierges_by_muni;
  var muni_conciergesListLen375 = muni_conciergesList375.length;
  for (var muni_conciergesIndex375 = 0; muni_conciergesIndex375 < muni_conciergesListLen375; muni_conciergesIndex375++) {
    var muni_conciergesData375 = muni_conciergesList375[muni_conciergesIndex375];
    var conciergeList376 = muni_conciergesData375.concierges;
    var conciergeListLen376 = conciergeList376.length;
    for (var conciergeIndex376 = 0; conciergeIndex376 < conciergeListLen376; conciergeIndex376++) {
      var conciergeData376 = conciergeList376[conciergeIndex376];
      Nightloop.Templates.Admin.ConciergeRecord(soy.$$augmentData(opt_data, {concierge: conciergeData376, muni_name: muni_conciergesData375.muni_name}), output);
    }
  }
  output.append('</tbody></table></div>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Admin.ConciergeRecord = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<tr class="container entity-record"><td><span class="concierge-name-only">', soy.$$escapeHtml(opt_data.concierge.name), '</span></td><td>', soy.$$escapeHtml(opt_data.concierge.url_key), '</td><td>', soy.$$escapeHtml(opt_data.concierge.id), '</td><td>', soy.$$escapeHtml(opt_data.concierge.group), '</td><td>', (opt_data.muni_name) ? soy.$$escapeHtml(opt_data.muni_name) : '&nbsp;', '</td><td class="right"><a class="ajaxify concierge-edit-link" href="/admin/concierge/', soy.$$escapeHtml(opt_data.concierge.id), '/edit">[edit]</a><a class=\'ajaxify\' href=\'/concierge/', soy.$$escapeHtml(opt_data.concierge.id), '/home\'>[go]</a> <a class=\'ajaxify\' href=\'/admin/user/entity/', soy.$$escapeHtml(opt_data.concierge.id), '/list\'>[users]</a></td></tr>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Admin.ConciergeTableHeader = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<thead><td>Name</td><td>URL key</td><td>ID</td><td>Group</td><td>Muni</td><td class="right" width="110">Actions</td></thead>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Admin.ConciergeTableScript = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<script type="text/javascript">\n      $(document).ready(function() {\n        $(\'#content\').width(1600);\n        var table = $(\'#concierge-list-table\').DataTable({\n          "displayLength": 100,\n          "lengthMenu": [[50, 100, 200, 500, -1], [50, 100, 200, 500, "All"]],\n        });\n        Pmp.Utils.attachDataTablesToSearch(table, \'q\')\n      });\n    <\/script>');
  return opt_sb ? '' : output.toString();
};
