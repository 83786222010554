// This file was automatically generated from admin.conciergeuser.edit.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Admin == 'undefined') { Nightloop.Templates.Admin = {}; }


Nightloop.Templates.Admin.ConciergeUserEdit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Admin.Layout(soy.$$augmentData(opt_data, {is_sevenrooms_admin: opt_data.content.is_sevenrooms_admin, DynamicTemplate: 'Nightloop.Templates.Admin.ConciergeUserEditBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Admin.ConciergeUserEditBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div class="content-header">Concierge-User-Access</div><h2>Editing concierge/user access for user \'', soy.$$escapeHtml(opt_data.content.user.first_name), ' ', soy.$$escapeHtml(opt_data.content.user.last_name), '\' at concierge \'', soy.$$escapeHtml(opt_data.content.concierge.name), '\'</h2><form class="ajaxify" method="post" action="/admin/conciergeuser/edit/', soy.$$escapeHtml(opt_data.content.form.fields.key.value), '"><input type="hidden" name="', soy.$$escapeHtml(opt_data.content.form.fields.key.name), '" value="', soy.$$escapeHtml(opt_data.content.form.fields.key.value), '"><div>Privilege Level<br/><select name="', soy.$$escapeHtml(opt_data.content.form.fields.privilege_level.name), '">');
  var choiceList611 = opt_data.content.form.fields.privilege_level.choices;
  var choiceListLen611 = choiceList611.length;
  for (var choiceIndex611 = 0; choiceIndex611 < choiceListLen611; choiceIndex611++) {
    var choiceData611 = choiceList611[choiceIndex611];
    output.append('<option value="', soy.$$escapeHtml(choiceData611[0]), '" ', (choiceData611[0] == opt_data.content.form.fields.privilege_level.value) ? 'selected="selected"' : '', '>', soy.$$escapeHtml(choiceData611[1]), '</option>');
  }
  output.append('</select></div><table><tr><td>Job Title:  </td><td><label><input type="text" name="', soy.$$escapeHtml(opt_data.content.form.fields.job_title.name), '" value="', soy.$$escapeHtml(opt_data.content.form.fields.job_title.value), '"/></label></td></tr></table><div class=\'italics\'>----- FINE GRAINED FEATURES -----</div><br/><table><tr><td>administrate user access:  </td><td><label><input type="radio" name="', soy.$$escapeHtml(opt_data.content.form.fields.f_user_admin.name), '" value=""', (opt_data.content.form.fields.f_user_admin.value == '') ? 'checked="checked"' : '', '/> Default</label> <label><input type="radio" name="', soy.$$escapeHtml(opt_data.content.form.fields.f_user_admin.name), '" value="1"', (opt_data.content.form.fields.f_user_admin.value == '1') ? 'checked="checked"' : '', '/> Yes</label> <label><input type="radio" name="', soy.$$escapeHtml(opt_data.content.form.fields.f_user_admin.name), '" value="0"', (opt_data.content.form.fields.f_user_admin.value == '0') ? 'checked="checked"' : '', '/> No</label></td></tr></table><br/><br/><input type="submit" name="submit" value="Submit" /></form>');
  return opt_sb ? '' : output.toString();
};
