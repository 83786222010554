// This file was automatically generated from admin.venue.availabilitydebugger.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Admin == 'undefined') { Nightloop.Templates.Admin = {}; }


Nightloop.Templates.Admin.VenueAvailabilityDebugger = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Admin.Layout(soy.$$augmentData(opt_data, {is_sevenrooms_admin: opt_data.content.is_sevenrooms_admin, DynamicTemplate: 'Nightloop.Templates.Admin.VenueAvailabilityDebuggerBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Admin.VenueAvailabilityDebuggerBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div id="page-admin-venue-availability-debugger"><div><a href="/admin/venue/', soy.$$escapeHtml(opt_data.content.venue.id), '/edit">&lt;&lt; back to venue edit</a></div><h3>', soy.$$escapeHtml(opt_data.content.venue.name), ' - Availability Debugger</h3><div></div><div><form action="/admin/venue/', soy.$$escapeHtml(opt_data.content.venue.id), '/availability-debugger" method="get"><table style="background-color: #fff;width:100%"><tr><td class="form-label" >Date:</td><td><input type="text" name="date" value="', soy.$$escapeHtml(opt_data.content.form.date), '" /></td></tr><tr><td class="form-label" >Party Size:</td><td><input type="text" name="party_size" value="', soy.$$escapeHtml(opt_data.content.form.party_size), '" /></td></tr><tr><td class="form-label" >Time:</td><td><input type="text" name="time" value="', soy.$$escapeHtml(opt_data.content.form.time), '" /></td></tr><tr><td class="form-label" >Client ID/Audience:</td><td><input type="text" name="audience" value="', soy.$$escapeHtml(opt_data.content.form.audience), '" /></td></tr><tr><td></td><td><input type="submit" value="Submit"></td></tr></table></form></div><div><h2>Results</h2><div><div style="color: ', (opt_data.content.is_bookable) ? '#0A0' : '#A00', '">', soy.$$escapeHtml(opt_data.content.result_description), '</div><br/><div><b>Why?</b><br/> <pre>', soy.$$escapeHtml(opt_data.content.debug_reason), '</pre></div>', (opt_data.content.result_contents) ? '<br/><hr/><br/><div><b>Details:</b><br/> <pre>' + soy.$$escapeHtml(opt_data.content.result_contents) + '</pre></div>' : '', (opt_data.content.developer_debug_reason) ? '<br/><hr/><br/><div><b>Developer Details:</b><br/> <pre>' + soy.$$escapeHtml(opt_data.content.developer_debug_reason) + '</pre></div>' : '', '</div></div></div>');
  return opt_sb ? '' : output.toString();
};
