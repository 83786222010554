var sr = sr || {};

// Use new sr.Validator(scope) syntax
sr.Validator = function(scope, currencySymbol, callback) {
	this.scope = scope;
	this.currencySymbol = (currencySymbol) ? currencySymbol : '$';
	this.callback = callback;
	this.activeFormAttr = 'sr-validate';
	this.activeFieldAttr = 'sr-validate';
	this.validatorGroupAttr = 'sr-validate-group';
	this.errorDisplayAttr = 'sr-validate-error';
	this.errorTargetAttr = 'sr-validate-error-target';
	this.injectFieldAttr = 'sr-validate-inject';
	this.orEmptySyntax = ':empty';
	this.treatAsVisibleSyntax = ':force-visible';
	this.callbackSyntax = ':callback';
	this.fieldParent = '.form-element';
	this.fieldDisabledClass = 'disabled';

	// subscribe to some events
	this.onValidateFail = null;
	this.onShouldValidateGroup = null;

	// debug mode shows extra logging
	this.debugMode = false;
};


sr.Validator.prototype.logDebug = function (msg) {
	if (this.debugMode) {
		console.log('validator(' + $(this.scope).selector + '): ' + msg);
	}
};

/*
 *
 * TODO:
 *  validate selects, radios, checkboxes
 *  'live' mode for keyup/change validation
 *
 */

// Run validation routines for all forms in a scope
sr.Validator.prototype.validate = function() {
	var that = this;
	var isValid = true;
	var firstInvalidField = null;
	var formHandler = function(form) {
		this.clearForm(form);
	};
	var fieldHandler = function(form,field) {
		var isFieldValid = this.validateField(field);
		isValid = (!isFieldValid) ? isFieldValid : isValid;
		if (!isFieldValid) {
		  if (firstInvalidField == null) {
		    firstInvalidField = field;
		  }
		}
	}

	that._eachField(formHandler,fieldHandler);

	if (!isValid) {
	  if (this.onValidateFail) {
	    this.onValidateFail(firstInvalidField);
	  }
	}

	return isValid;
};

sr.Validator.prototype.validateField = function(field) {
	var that = this;
	var isValid = true;
	// short circuit for disabled fields
	var isDisabled = field.hasClass('disabled') || field.attr('disabled');
	if (isDisabled) {
		that.logDebug('...found disabled field, skipping');
		return true;
	}

	var validationType = field.attr(that.activeFieldAttr) || field.data(that.activeFieldAttr);
	var orEmpty = validationType.search(that.orEmptySyntax) != -1;
	var treatAsVisible = validationType.search(that.treatAsVisibleSyntax) != -1;


	if (orEmpty) {
		validationType = validationType.replace(that.orEmptySyntax,'');
	}

	if(treatAsVisible) {
		validationType = validationType.replace(that.treatAsVisibleSyntax, '');
	}

	var useCallback = validationType.search(that.callbackSyntax) != -1;
	if (useCallback) {
	  validationType = validationType.replace(that.callbackSyntax,'');
	}

	var validateFnName = 'validate_'+validationType; //note hungarian notation of validation routines
	var validatorFn = that[validateFnName];

	if (!that._isFn(validatorFn,'test')) {
	  console.log('Field #'+ field.attr('id') + ': could not find validator ' + validateFnName);
	  return isValid;
	}

	that.logDebug('...invoking validator ' + validateFnName);
	var dependencies = that._getFieldDependencies(field, validatorFn);
	var val = (that._isFn(validatorFn,'value')) ? validatorFn.value(field) : that.value(field);
	var args = [val].concat(dependencies);
	var isFieldValid = validatorFn.test.apply(that,args);
	var fieldHasData = (!val) ? false : val.length > 0;
	if ((fieldHasData && !isFieldValid)
	    || (!fieldHasData && !orEmpty && !isFieldValid)) {
	  isValid = false;
	}

	if (useCallback) {
	  if (that.callback) {
	    isValid = that.callback(field, validatorFn, isValid);
	  } else {
	    console.log('No callback function given!');
	  }
	}

	if (!isValid) {
		that.logDebug('...found error for field ' + field.attr('name'));
	  that._showFieldError(field);
	} else {
		that.logDebug('...found valid field ' + field.attr('name'));
	}

	return isValid;
};

sr.Validator.prototype._eachField = function(formHandler,fieldHandler) {
	var that = this;
	$(this.scope).find('form['+this.activeFormAttr+'],form[data-'+this.activeFormAttr+']').each(function(){
		var form = $(this);
		var alwaysValidate = form.attr(that.activeFormAttr) === 'always' || form.data(that.activeFormAttr) === 'always';
		var isVisible = form.is(':visible');
		var shouldValidate = alwaysValidate || isVisible;
		if (!shouldValidate) {
		  return true; // continue
		}

		if (shouldValidate) {
			that.logDebug('processing form ' + form.attr('id'));
		} else {
			that.logDebug('ignoring invisible form ' + form.attr('id'));
		}


		formHandler.call(that, form);

		var validationElements = form.find('[' + that.activeFieldAttr + ']');
    if( !validationElements.length ) {
	  that.logDebug('found [data-xx] elements');
      validationElements = form.find('[data-' + that.activeFieldAttr + ']');
    }
		validationElements.each(function() {
			var field = $(this);

			// Allows hidden form elements like those used in pickers to be validated as well
			var isFieldVisible = field.is(':visible');
			var attrValue = field.attr(that.activeFieldAttr) || field.data(that.activeFieldAttr);
			var forceVisible = attrValue ? attrValue.search(that.treatAsVisibleSyntax) >= 0 : false;
			var shouldValidate = alwaysValidate || isFieldVisible || forceVisible;

			if (shouldValidate) {
				that.logDebug('validating field ' + field.attr('name'));
			} else {
				that.logDebug('ignoring invisible field ' + field.attr('name'));
			}

			if (!shouldValidate) {
			  return true; // continue
			}

			// check the validator group for this field
			var validatorGroup = field.parents('[' + that.validatorGroupAttr + ']');

      		if( !validatorGroup.length ) {
      			validatorGroup = field.parents('[data-' + that.validatorGroupAttr + ']');
      		}
			if (validatorGroup.length) {
			  if (that.onShouldValidateGroup) {
					var groupAttrVal = validatorGroup.attr(that.validatorGroupAttr);
					var groupDataVal = validatorGroup.data(that.validatorGroupAttr);
					if (groupAttrVal) {
						shouldValidate = that.onShouldValidateGroup(groupAttrVal);
					} else if (groupDataVal) {
						shouldValidate = that.onShouldValidateGroup(groupDataVal);
					}
			  }
			}

			if (!shouldValidate) {
				that.logDebug('validator group prevented validation on field ' + field.attr('name'));
			  return true; // continue
			}

			fieldHandler.call(that,form,field);

		});
	});
};

sr.Validator.prototype._getFieldDependencies = function(field, validatorFn) {
	var that = this;
	var dependencies = field.attr(that.injectFieldAttr) || field.data(that.injectFieldAttr);
	if (dependencies != undefined) {
		dependencies = dependencies.split(',').map(function(str){
			var el = $($.trim(str));
			return (that._isFn(validatorFn,'value')) ? validatorFn.value(el) : that.value(el);
		});
	}
	return (dependencies) ? dependencies : [];
};

sr.Validator.prototype._isFn = function(obj,fnName) {
	if (!obj) {
		return false;
	}
	return (typeof obj[fnName] == 'function');
};

sr.Validator.prototype._showFieldError = function(field) {
	var that = this;
	var fieldErrorTarget = field.attr(that.errorTargetAttr) || field.data(that.errorTargetAttr);
	var errorDisplayElement = null;
	if (fieldErrorTarget) {
		errorDisplayElement = $(fieldErrorTarget);
	} else {
		errorDisplayElement = field.closest(that.fieldParent).find('[' + that.errorDisplayAttr + ']');
    if( !errorDisplayElement.length ) {
		  errorDisplayElement = field.closest(that.fieldParent).find('[data-' + that.errorDisplayAttr + ']');
    }
	}
	that.displayError(errorDisplayElement);
};

// Clear all errors for all forms in a scope
sr.Validator.prototype.clear = function(scope) {
	var that = this;
	$(this.scope).find('form[' + this.activeFormAttr + ']').filter(':visible').each(function(){
		var form = $(this);
		that.logDebug('clearing form ' + form.selector);
		that.clearForm(form);
	});
};

// Clear errors for a single form
sr.Validator.prototype.clearForm = function (form) {
	$(form).find('[' + this.errorDisplayAttr + ']').hide();
	$(form).find('[data-' + this.errorDisplayAttr + ']').hide();
};

// Display error for a field
sr.Validator.prototype.displayError = function(element) {
	if (element) {
		$(element).show();
	}
};

// Retrieve data value from form element
sr.Validator.prototype.value = function(element) {
	// TODO: does .val() always work for every input, textarea, etc.?
	var el = $(element);
	return (el.length > 0) ? $.trim(el.val()) : undefined;
};

// Replace validator type for a field
sr.Validator.prototype.change = function(field_id,validator, dependencies) {
	var injector = dependencies;
	if (dependencies == undefined) {
		injector = '';
	}
	$(field_id).attr(this.activeFieldAttr, validator);
	$(field_id).attr(this.injectFieldAttr, injector);
	$(field_id).data(this.activeFieldAttr, validator);
	$(field_id).data(this.injectFieldAttr, injector);
};

/*
 * Validation Routines below, generically reusable
 * - take only value types as input
 * - return true or false
 */

sr.Validator.prototype.validate_not_empty = {
	test : function (value) {
		return ($.trim(value).length > 0);
	}
};


sr.Validator.prototype.validate_access_time_in_bounds = {
  test: function (count, unit) {
    if (count === null) {
      return false
    }
    var countInt = parseInt(count)

    if (countInt <= 0) {
      return false
    }

    switch (unit) {
      case 'MONTHS':
        return countInt <= 24
      case 'WEEKS':
        return countInt <= 104
      case 'DAYS':
        return countInt <= 730
      case 'HOURS':
        return countInt <= 100
      case 'MINUTES':
      default:
        return countInt <= 6000
    }
  },
}

sr.Validator.prototype.validate_strict_int_positive = {
	test : function(value) {
		return value.match(/^[0-9]+$/) !== null && Number(value) > 0;
	}
};

sr.Validator.prototype.validate_int_positive = {
	test : function(value) {
		return value.match(/^[0-9]+$/) !== null;
	}
};

sr.Validator.prototype.validate_int_positive_or_empty = {
	test : function(value) {
		return  value == '' || parseInt(value) > 0;
	}
};

sr.Validator.prototype.validate_positive_number = {
	test : function(value) {
		return value.match(/^[0-9|\.]+$/) !== null && Number(value) >= 0 && Number(value) < 100;
	}
};

sr.Validator.prototype.validate_positive_float_or_empty = {
	test : function(value) {
		return (value === '' || (value.match(/^[0-9|\.]+$/) !== null && Number(value) >= 0));
	}
};

sr.Validator.prototype.validate_positive_float = {
	test : function(value) {
		return value.match(/^[0-9|\.]+$/) !== null && Number(value) >= 0;
	}
};

sr.Validator.prototype.validate_datepicker_date = {
	value : function(input) {
		var val = input.datepicker('getDate');
		if (input.datepicker('getDate') === null) {
			input.datepicker('setDate', null); // This clears out the localized hidden input if the user cleared the visible input
		}
		return val;
	},
	test : function(value) {
		if (value === null) {
			return false;
		} else {
			return true;
		}
	}
};

sr.Validator.prototype.validate_checked_and_not_empty = {
  value : function(input) {
    return input[0];
  },

  test : function(check) {
    if (!check.checked) {
      return true;
    }
    // Support multiple list of dependency arguments
    for (var i = 1; i < arguments.length; i++) {
      var field = arguments[i];
      if (field.value == '') {
        return false;
      }
    }
    return true;
  }
};

// TODO: weird side effect of 'or' validators that have dependencies. order matters doh!
sr.Validator.prototype.validate_phone_or_email = {
	test : function(phone, phoneLocale, email) {
		return this.validate_email_or_phone.test.call(this,email, phone, phoneLocale);
	}
};

sr.Validator.prototype.validate_email_or_phone = {
	test : function(email,phone,phoneLocale) {
		var isEmailValid = this.validate_email.test.call(this,email) ||
			sr.Validator.prototype.validate_email_or_phone.is_masked(email);
		var isPhoneValid = this.validate_phone.test.call(this,phone,phoneLocale) ||
			sr.Validator.prototype.validate_email_or_phone.is_masked(phone);
		return (isEmailValid || isPhoneValid);
	},

	is_masked : function(str) {
		return str && str.indexOf('**') > -1;
	}
};

sr.Validator.prototype.validate_phone = {
	test : function(phone, phone_locale) {
		this.logDebug(phone)
		this.logDebug(phone_locale)
		if (phone_locale === 'US') {
			phone = phone.replace(/[\+]1/ig, ''); // remove +1's
			phone = phone.replace(/[^\d]/ig, '');
		  var phoneRe = /^[+]?([1]{1})?[-\. ]?\(?([0-9]{3})\)?[-\. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
		  return phoneRe.test(phone);
		} else {
			// INTL
			return true;
		}
	}
};

sr.Validator.prototype.validate_email = {
    test: function (value) {
        // This is the same regexp as used in sr/util/emails.py
        const emailRegexp = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i
        return emailRegexp.test(value);
    }
};

sr.Validator.prototype.validate_price = {
	test : function (value) {
		var priceStripped = Pmp.Utils.Currency.RawDecimal(value, this.currencySymbol);
		return priceStripped.match(/^[0-9]+(\.[0-9]{2})*$/) !== null;
	}
};

sr.Validator.prototype.validate_new_booked_by = {
	test : function (new_name, booked_by_val, is_concierge) {
	  if (is_concierge) {
	    return true;
	  }

		if (booked_by_val === '--new--') {
			return this.validate_not_empty.test(new_name);
		}
		return true;
	}
};

sr.Validator.prototype.validate_ssn_last_four = {
    test : function (value) {
      var valid = this.validate_int_positive.test.call(this,value);
      if (valid) {
        valid = value.length == 4;
      }
      return valid;
    }
};

sr.Validator.prototype.validate_credit_card_number = {
    test : function (value) {
      var valid = $.payment.validateCardNumber(value);
      return valid;
    }
};

sr.Validator.prototype.validate_credit_card_expiry_month = {
    test : function (exp_month, exp_year) {
      var valid = $.payment.validateCardExpiry(exp_month,exp_year);
      return valid;
    }
};

sr.Validator.prototype.validate_credit_card_expiry_year = {
    test : function (exp_year, exp_month) {
      var valid = $.payment.validateCardExpiry(exp_month,exp_year);
      return valid;
    }
};

sr.Validator.prototype.validate_credit_card_cvc = {
    test : function (cvc, cc_num) {
      var cardType = $.payment.cardType(cc_num);
      var valid = $.payment.validateCardCVC(cvc,cardType);
      return valid;
    }
};

sr.Validator.prototype.validate_has_tag = {
  value : function(input) {
    return $(input).find('.tag-item').length;
  },

  test : function(value) {
    return value > 0;
  }
};

sr.Validator.prototype.validate_has_picker_option = {
  value: function (input) {
    return $(input).find('.selected_option').length;
  },

  test: function (value) {
    return value > 0;
  }
};

sr.Validator.prototype.validate_has_autocomplete_tag = {
  value: function (input) {
    return $(input).find('.tag').length;
  },
  test: function(value) {
  	return value > 0;
  }
};
