// This file was automatically generated from admin.user.import.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Admin == 'undefined') { Nightloop.Templates.Admin = {}; }


Nightloop.Templates.Admin.UserImport = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Admin.Layout(soy.$$augmentData(opt_data, {is_sevenrooms_admin: opt_data.content.is_sevenrooms_admin, DynamicTemplate: 'Nightloop.Templates.Admin.UserImportBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Admin.UserImportBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div id="page-admin-users-import"><div class="content-header">', (opt_data.content.entity_name) ? soy.$$escapeHtml(opt_data.content.entity_name) + ' ' : '', 'User Import</div>', (opt_data.content.error_msg) ? '<div class="error-message">' + soy.$$escapeHtml(opt_data.content.error_msg) + '</div>' : '', '<form id="import-users-form" method="POST" action="/admin/user/entity/', soy.$$escapeHtml(opt_data.content.entity_id), '/import"><div class="white-box">');
  if (opt_data.content.is_preview) {
    output.append('<input type="hidden" name="confirm" id="users-import-confirm-input" value="true" /><div id="users-import-confirm-display"><div class="white-box-block">Please confirm the following preview:</div><div class="white-box-block"><div>First Name, Last Name, Email Address, Default Password</div>');
    var user_entryList1814 = opt_data.content.users_preview;
    var user_entryListLen1814 = user_entryList1814.length;
    for (var user_entryIndex1814 = 0; user_entryIndex1814 < user_entryListLen1814; user_entryIndex1814++) {
      var user_entryData1814 = user_entryList1814[user_entryIndex1814];
      output.append('<div>', soy.$$escapeHtml(user_entryData1814.first_name), ',', soy.$$escapeHtml(user_entryData1814.last_name), ',', soy.$$escapeHtml(user_entryData1814.email_address), ',', soy.$$escapeHtml(user_entryData1814.preview_password), '</div>');
    }
    output.append('</div></div>');
  }
  output.append('<div id="users-import-preview-display" class="', (opt_data.content.is_preview) ? 'no-display' : '', '"><div class="white-box-block"><div class="float-left w65">Use this tool to add many users all at once. <br/></div><div class="float-end"></div></div><div class="white-box-block"><div id="users-batch-input-container"><div id="users-batch-input-prompt">First Name, Last Name, Email Address, Default Password, Phone Number<br/>...</div><textarea name="users_batch" id="users-batch">', soy.$$escapeHtml(opt_data.content.users_batch), '</textarea></div></div></div><div class="white-box-block">');
  if (opt_data.content.is_preview) {
    output.append('<div id="users-import-confirm-buttons" class="float-right"><div class="link-next-to-button-div float-left"><a id="users-import-cancel-btn" class="close-main-colorbox-link" href="javascript:void(0);">cancel</a></div> ');
    Nightloop.Templates.Widget.GoldButton({text: 'GO BACK', size: 'medium', id: 'users-import-makechanges-btn'}, output);
    Nightloop.Templates.Widget.GoldButton({text: 'CONFIRM', size: 'medium', id: 'users-import-confirm-btn'}, output);
    output.append('</div>');
  }
  output.append('<div id="users-import-preview-buttons" class="float-right ', (opt_data.content.is_preview) ? 'no-display' : '', '"><div class="link-next-to-button-div float-left"><a id="users-import-cancel-btn" class="close-main-colorbox-link" href="javascript:void(0);">cancel</a></div> ');
  Nightloop.Templates.Widget.GoldButton({text: 'PREVIEW', size: 'medium', id: 'users-import-preview-btn'}, output);
  output.append('</div><div class="float-end"></div></div></div></form><script type="text/javascript">$(document).ready( function() {Pmp.Admin.Users.Import.initialize();});<\/script></div>');
  return opt_sb ? '' : output.toString();
};
