// This file was automatically generated from widget.followers.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Widget == 'undefined') { Nightloop.Templates.Widget = {}; }


Nightloop.Templates.Widget.Followers = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div class="followers-component">', (opt_data.title) ? '<div class=\'title\'>Followers</div>' : '', '<span class="frozen-followers-area">');
  var followerList7960 = opt_data.frozenFollowers;
  var followerListLen7960 = followerList7960.length;
  for (var followerIndex7960 = 0; followerIndex7960 < followerListLen7960; followerIndex7960++) {
    var followerData7960 = followerList7960[followerIndex7960];
    output.append('<span class="pic">', soy.$$escapeHtml(followerData7960.initials), '<em class=\'name\'>', soy.$$escapeHtml(followerData7960.full_name), '</em></span>');
  }
  output.append('</span><span class="followers-area">');
  var followerList7968 = opt_data.followers;
  var followerListLen7968 = followerList7968.length;
  for (var followerIndex7968 = 0; followerIndex7968 < followerListLen7968; followerIndex7968++) {
    var followerData7968 = followerList7968[followerIndex7968];
    output.append('<span class="pic">', soy.$$escapeHtml(followerData7968.initials), '<span class="del follower-remove" fid="', soy.$$escapeHtml(followerData7968.id), '">x</span>', (opt_data.includeInput) ? '<input type="hidden" name="fid" value="' + soy.$$escapeHtml(followerData7968.id) + '" />' : '', '<em class=\'name\'>', soy.$$escapeHtml(followerData7968.full_name), '</em></span>');
  }
  output.append('</span><span id="insert-followers" class="add"><img src="', soy.$$escapeHtml(opt_data.MEDIA_URL), 'images/add_2x.png" /><select id="followers-select"></select></span></div>');
  return opt_sb ? '' : output.toString();
};
