// This file was automatically generated from admin.whitelists.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Admin == 'undefined') { Nightloop.Templates.Admin = {}; }


Nightloop.Templates.Admin.WhiteLists = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Admin.Layout(soy.$$augmentData(opt_data, {is_sevenrooms_admin: opt_data.is_sevenrooms_admin, DynamicTemplate: 'Nightloop.Templates.Admin.WhiteListsBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Admin.WhiteListsBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div id="page-admin-utilities">', (opt_data.success_message) ? '<div class="gold-message">' + soy.$$escapeHtml(opt_data.success_message) + '</div>' : '', (opt_data.error_message) ? '<div class="error-message">' + soy.$$escapeHtml(opt_data.error_message) + '</div>' : '', '<h2>SMS Phone Number White List</h2>');
  if (opt_data.IS_PRODUCTION) {
    output.append('<h5>All phone numbers are enabled for SMS in production.</h5>');
  } else {
    output.append('<h5>Only phone numbers in the list below are enabled for outbound SMS in this environment</h5><p><table><tr><td>Phone Number</td><td>Owner Name</td><td>Added By</td><td>Updated</td><td>Expires</td><td /></tr>');
    var phone_number_entryList8273 = opt_data.phone_number_whitelist_store_for_testing;
    var phone_number_entryListLen8273 = phone_number_entryList8273.length;
    for (var phone_number_entryIndex8273 = 0; phone_number_entryIndex8273 < phone_number_entryListLen8273; phone_number_entryIndex8273++) {
      var phone_number_entryData8273 = phone_number_entryList8273[phone_number_entryIndex8273];
      output.append('<tr><td>', soy.$$escapeHtml(phone_number_entryData8273.value), '</td><td>', soy.$$escapeHtml(phone_number_entryData8273.name), '</td><td>', soy.$$escapeHtml(phone_number_entryData8273.added_by), '</td><td>', soy.$$escapeHtml(phone_number_entryData8273.updated_ts_display), '</td><td>', soy.$$escapeHtml(phone_number_entryData8273.expiration_ts_display), '</td><td><form method="post" action="/admin/whitelists"><input type="hidden" name="revoke-phone-number-white-list" value="1" /><input type="hidden" name="phone_number" value="', soy.$$escapeHtml(phone_number_entryData8273.value), '" /><input type="submit" value="Revoke Access" /></form></td></tr>');
    }
    output.append('</table><form method="post" action="/admin/whitelists"><input type="hidden" name="grant-phone-number-white-list" value="1" /><h5>Grant New Phone Number Access:</h5><table><tr><td class="form-label">Phone Number:</td><td><input type="text" name="phone_number" /></td></tr><tr><td class="form-label">Country Code:</td><td><select name="country_code">');
    var country_codeList8289 = opt_data.country_codes;
    var country_codeListLen8289 = country_codeList8289.length;
    for (var country_codeIndex8289 = 0; country_codeIndex8289 < country_codeListLen8289; country_codeIndex8289++) {
      var country_codeData8289 = country_codeList8289[country_codeIndex8289];
      output.append('<option value="', soy.$$escapeHtml(country_codeData8289), '" ', (country_codeData8289 == 'us') ? 'selected="selected' : '', '">', soy.$$escapeHtml(country_codeData8289), '</option>');
    }
    output.append('</select></td></tr><tr><td class="form-label">Owner Name:</td><td><input type="text" name="name" /></td></tr><tr><td class="form-label">Expires After:</td><td><input type="number" name="expires_days" placeholder="days" /> (Number of days access is in force, or blank for indefinite)</td></tr><tr><td /><td><input type="submit" value="Add Phone Number" /></td></tr></table></form></p>');
  }
  output.append('<h2>Email White List</h2>');
  if (opt_data.IS_PRODUCTION) {
    output.append('<h5>All emails are enabled to receive in production.</h5>');
  } else {
    output.append('<h5>Only emails in the list below are enabled to receive in this environment.*</h5><p>*Additionally, any <a href="https://support.google.com/a/users/answer/9308648">task-specific email addresses</a>including a "+" label (like mailroom+test@sevenrooms.com) will be sent.</p><p><table><tr><td>Email</td><td>Owner Name</td><td>Added By</td><td>Updated</td><td>Expires</td><td /></tr>');
    var email_entryList8307 = opt_data.email_whitelist_store_for_testing;
    var email_entryListLen8307 = email_entryList8307.length;
    for (var email_entryIndex8307 = 0; email_entryIndex8307 < email_entryListLen8307; email_entryIndex8307++) {
      var email_entryData8307 = email_entryList8307[email_entryIndex8307];
      output.append('<tr><td>', soy.$$escapeHtml(email_entryData8307.value), '</td><td>', soy.$$escapeHtml(email_entryData8307.name), '</td><td>', soy.$$escapeHtml(email_entryData8307.added_by), '</td><td>', soy.$$escapeHtml(email_entryData8307.updated_ts_display), '</td><td>', soy.$$escapeHtml(email_entryData8307.expiration_ts_display), '</td><td><form method="post" action="/admin/whitelists"><input type="hidden" name="revoke-email-white-list" value="1" /><input type="hidden" name="email" value="', soy.$$escapeHtml(email_entryData8307.value), '" /><input type="submit" value="Revoke Access" /></form></td></tr>');
    }
    output.append('</table><form method="post" action="/admin/whitelists"><input type="hidden" name="grant-email-white-list" value="1" /><h5>Grant New Email Access:</h5><table><tr><td class="form-label">Email:</td><td><input type="text" name="email" /></td></tr><tr><td class="form-label">Owner Name:</td><td><input type="text" name="name" /></td></tr><tr><td class="form-label">Expires After:</td><td><input type="number" name="expires_days" placeholder="days" /> (Number of days access is in force, or blank for indefinite)</td></tr><tr><td /><td><input type="submit" value="Add Email" /></td></tr></table></form></p>');
  }
  output.append('<script type="text/javascript"><\/script></div>');
  return opt_sb ? '' : output.toString();
};
